import React from 'react';
import withLayout from '../layout/withLayout';
import Seo from "../components/Seo";
import styled from 'styled-components';
import { GM2021 } from './general_meetings';

const DownloadButton = styled.a`
    padding: 10px 20px;
    font-size: 12px;
`

const InvestorsRelation = withLayout(({ siteData }) => {

    return (
        <>
            <Seo title="Annual General Meetings" description="NEMCARE Tezpur Annual General meetings" siteMetadata={siteData} />
            <div className="page-title-area page-title-bg3">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Investors Relation</h2>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/about">About</a>
                                    </li>
                                    <li>Investors Relation</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GM2021 />
        </>
    );
})

export default InvestorsRelation;
import React from 'react';
import withLayout from '../layout/withLayout';
import Seo from "../components/Seo";
import styled from 'styled-components';

const DownloadButton = styled.a`
    padding: 10px 20px;
    font-size: 12px;
`

const GM2021 = () => {
    return (
        <section className="contact-area mt-5" style={{ minHeight: 500 }}>
            <div className="container">
                <div
                    className="contact-inner"
                    style={{ boxShadow: "none" }}
                >
                    <div className="row">
                        <div className={"col-sm-12"}>
                            <div
                                className={"services-details-desc"}
                                style={{ boxShadow: "none" }}
                            >
                                <h3 style={{ margin: 0, padding: 0 }}>2021 - Annual General Meeting</h3>
                                <p>Report and Notice about Annual general meetings for the year 2021</p>
                                <table className={"description_table mt-4"}>
                                    <tbody>

                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                Scrutinizer Report
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/NEMCARE_TEZPUR_SCRUTINIZER_REPORT.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                Annual Report 2020-2021
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/general_meeting_2021/annual_report_2020_2021.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                Notice of Annual General Meeting
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/general_meeting_2021/notice.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                Newspaper Advertisement - Amar Axom
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/general_meeting_2021/paperadd_amar_axom.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                Newspaper Advertisement (English) - The Sentinel
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/general_meeting_2021/paperadd_eng.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className={"bullet_cell"}>
                                                <i className="fas fa-check"></i>{" "}
                                            </td>
                                            <td>
                                                FORM MGT19
                                            </td>
                                            <td>
                                                <DownloadButton target="_blank" rel="noopener noreferer" href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/general_meeting_2021/MGT-9.pdf" className="btn btn-primary">
                                                    Download
                                                    {/* <i className="flaticon-next"></i> */}
                                                </DownloadButton>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const GeneralMeetings = withLayout(({ siteData }) => {

    return (
        <>
            <Seo title="Annual General Meetings" description="NEMCARE Tezpur Annual General meetings" siteMetadata={siteData} />
            <div className="page-title-area page-title-bg3">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Annual General meetings</h2>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/about">About</a>
                                    </li>
                                    <li>Annual general Meetings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GM2021 />
        </>
    );
})

export { GM2021 };
export default GeneralMeetings;